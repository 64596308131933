<template>
  <div
    class="flex flex-col items-center p-40 my-40 border rounded border-grey-300"
  >
    <h6 class="text-center">{{ $t('mfa.check_email') }}</h6>
    <p class="mt-20 text-center">{{ $t('mfa.explanation') }}</p>
    <p class="mt-20 text-center">{{ $t('mfa.code_sent') }}</p>
    <CodeInput class="mt-20" :codeError="codeError" @confirm="confirm" />
    <div v-if="showEmailSent" class="flex items-center mt-20">
      <Icon iconClass="far fa-check-circle text-green-500" />
      <span class="ml-10">{{ $t('mfa.email_sent') }}</span>
    </div>
    <a
      v-else-if="isEmail"
      class="mt-20 text-blue-500 underline"
      @click="resend"
    >
      {{ $t('mfa.resend_email') }}
    </a>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import CodeInput from '@components/common/forms/CodeInput'

export default {
  components: { CodeInput },
  props: {
    codeError: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showEmailSent: false,
    }
  },
  methods: {
    ...mapActions('member', ['requestMfaCode']),
    confirm(totp) {
      this.$emit('submitMfaCode', totp)
    },
    confirmResend() {
      this.showEmailSent = true
      setTimeout(() => {
        this.showEmailSent = false
      }, 5000)
    },
    resend() {
      this.requestMfaCode().then(this.confirmResend)
    },
  },
}
</script>
