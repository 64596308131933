var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('RegistrationLayoutTitles',{attrs:{"title":_vm.$t('registration.password.title')}}),_c('div',{staticClass:"md:w-435 md:mx-auto"},[(_vm.showVerifyMfa)?_c('VerifyMfa',{attrs:{"codeError":_vm.mfaCodeError},on:{"submitMfaCode":_vm.submitMfaCode}}):_c('div',[_c('Form',{attrs:{"validator":_vm.$v.model,"error":_vm.error,"messages":{
          matching: _vm.$t('registration.password.matching_error'),
        }}},[_c('FormInput',{ref:"username",staticClass:"mt-20 mb-10",attrs:{"label":_vm.$t('registration.password.username'),"name":"username","type":"text"},model:{value:(_vm.model.username),callback:function ($$v) {_vm.$set(_vm.model, "username", $$v)},expression:"model.username"}}),_c('FormInput',{ref:"password",staticClass:"mt-20 mb-10",attrs:{"label":_vm.$t('registration.password.new_password'),"name":"password","placeholder":"••••••••","secure":"","type":"password"},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_c('PasswordRequirements',{attrs:{"reqs":_vm.passwordRequirements}}),_c('FormInput',{ref:"confirmPassword",staticClass:"mt-12 mb-10",attrs:{"label":_vm.$t('registration.password.confirm_password'),"name":"confirm","placeholder":"••••••••","secure":"","type":"password"},model:{value:(_vm.model.confirm),callback:function ($$v) {_vm.$set(_vm.model, "confirm", $$v)},expression:"model.confirm"}}),_c('div',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hasReadTerms),expression:"hasReadTerms"}],staticClass:"self-center ml-16 mr-10",attrs:{"id":"terms-checkbox","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.hasReadTerms)?_vm._i(_vm.hasReadTerms,null)>-1:(_vm.hasReadTerms)},on:{"change":function($event){var $$a=_vm.hasReadTerms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.hasReadTerms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.hasReadTerms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.hasReadTerms=$$c}}}}),_c('label',{attrs:{"for":"terms-checkbox"}},[_vm._v(" "+_vm._s(_vm.$t('registration.password.agreement'))+" "),_c('router-link',{staticClass:"underline",attrs:{"to":{
                name: 'public-privacy-document',
                params: { title: _vm.$t('common.terms_link') },
              },"title":_vm.$t('common.terms'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.terms')))])],1)]),_c('div',{staticClass:"flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hasReadPrivacy),expression:"hasReadPrivacy"}],staticClass:"self-center ml-16 mr-10",attrs:{"id":"privacy-checkbox","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.hasReadPrivacy)?_vm._i(_vm.hasReadPrivacy,null)>-1:(_vm.hasReadPrivacy)},on:{"change":function($event){var $$a=_vm.hasReadPrivacy,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.hasReadPrivacy=$$a.concat([$$v]))}else{$$i>-1&&(_vm.hasReadPrivacy=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.hasReadPrivacy=$$c}}}}),_c('label',{attrs:{"for":"privacy-checkbox"}},[_vm._v(" "+_vm._s(_vm.$t('registration.password.agreement'))+" "),_c('router-link',{staticClass:"underline",attrs:{"to":{
                name: 'public-privacy-document',
                params: { title: _vm.$t('common.privacy_link') },
              },"title":_vm.$t('common.privacy'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('common.privacy')))])],1)])],1),_c('HMButton',{staticClass:"w-full tracking-wide",class:{ 'mt-10': _vm.formHasError, 'mt-25': !_vm.formHasError },attrs:{"id":"finishButton","busy":_vm.isFetching,"disabled":!_vm.hasReadTerms || !_vm.hasReadPrivacy || _vm.isFetching,"rounded":"full","fontWeight":"semibold"},on:{"click":_vm.handleFinish}},[_vm._v(" "+_vm._s(_vm.usesAutoRewardsInRegistration ? _vm.$t('common.next_btn') : _vm.$t('common.finish_btn'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }