<template>
  <div>
    <RegistrationLayoutTitles
      :title="$t('registration.contact_info.title', [firstName])"
      :subTitle="$t('registration.contact_info.description')"
    />
    <div class="md:w-435 md:mx-auto">
      <Form
        :validator="$v.model"
        :error="error"
        :messages="{
          minLength: this.$t('registration.contact_info.length_error'),
          maxLength: this.$t('registration.contact_info.length_error'),
        }"
      >
        <div class="flex w-full">
          <Icon iconClass="fas fa-at" class="text-xl" />
          <div class="w-full pl-12">
            <FormInput
              v-model="model.emailRegister"
              :label="$t('registration.contact_info.email')"
              name="emailRegister"
              :placeholder="$t('registration.contact_info.email_place_holder')"
              autocomplete="email"
            />
            <p class="text-sm mt-5 text-grey-600">
              {{
                $t('registration.contact_info.email_disclaimer', [
                  $settings.NAME,
                ])
              }}
            </p>
          </div>
        </div>
        <div class="flex w-full mt-16">
          <Icon iconClass="fas fa-phone" class="text-xl" />
          <FormInput
            v-model="model.phone1.textValue"
            mask="###-###-####"
            class="flex-auto pl-12"
            :label="$t('registration.contact_info.phone_1')"
            name="phone1.textValue"
            :placeholder="$t('registration.contact_info.phone_place_holder')"
            autocomplete="tel-national"
          />
          <div class="self-end w-100">
            <label for="phone1.selectValue" class="sr-only">
              {{ $t('registration.contact_info.phone_type') }}
            </label>
            <FormListPicker
              v-model="model.phone1.selectValue"
              name="phone1.selectValue"
              :placeholder="$t('registration.contact_info.phone_type')"
              label=""
              :data="phoneTypes"
            />
          </div>
        </div>
        <div class="flex w-full mt-16 pl-30">
          <FormInput
            v-model="model.phone2.textValue"
            mask="###-###-####"
            class="flex-auto"
            :label="$t('registration.contact_info.phone_2')"
            name="phone2.textValue"
            :placeholder="$t('registration.contact_info.phone_place_holder')"
            :required="false"
            autocomplete="tel-national"
          />
          <div class="self-end w-100">
            <label for="phone2.selectValue" class="sr-only">
              {{ $t('registration.contact_info.phone_type') }}
            </label>
            <FormListPicker
              v-model="model.phone2.selectValue"
              name="phone2.selectValue"
              :placeholder="$t('registration.contact_info.phone_type')"
              label=""
              :data="phoneTypes"
              :required="false"
            />
          </div>
        </div>
      </Form>
      <div class="w-full ml-auto pl-30">
        <HMButton
          id="nextButton"
          rounded="full"
          fontWeight="semibold"
          class="w-full tracking-wide"
          :class="{ 'mt-10': formHasError, 'mt-25': !formHasError }"
          @click="handleNext"
        >
          {{ $t('common.confirm') }}
        </HMButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, FormInput } from '../../common/forms'
import RegistrationLayoutTitles from '@components/layouts/RegistrationLayoutTitles'
import FormListPicker from '../../common/forms/FormListPicker'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { STATES } from '@platform-shared/constants'
function uniqueType() {
  return this.model.phone1.selectValue !== this.model.phone2.selectValue
}
export default {
  components: { Form, FormInput, FormListPicker, RegistrationLayoutTitles },
  data() {
    return {
      phoneTypes: [
        { display: 'Mobile', value: 'CELL' },
        { display: 'Home', value: 'HOME' },
        { display: 'Work', value: 'WORK' },
      ],
      states: STATES,
      model: {
        emailRegister: '',
        phone1: {
          textValue: '',
          selectValue: 'CELL',
        },
        phone2: {
          textValue: '',
          selectValue: 'HOME',
        },
      },
      error: null,
    }
  },
  validations: {
    model: {
      emailRegister: {
        required,
        email,
      },
      phone1: {
        textValue: {
          minLength: minLength(12),
          maxLength: maxLength(12),
        },
        selectValue: {},
      },
      phone2: {
        textValue: {
          uniqueType,
          minLength: minLength(12),
          maxLength: maxLength(12),
        },
        selectValue: {},
      },
    },
  },
  computed: {
    ...mapGetters('registration', [
      'firstName',
      'registrationInfo',
      'registrationAutofillPhone',
    ]),
    ...mapGetters('member', ['memberInfo']),
    formHasError() {
      return !!this.error
    },
  },
  mounted() {
    //for SSO registration, memberInfo has data
    //for non-SSO registration, memberInfo is empty; registrationInfo has data
    const memberInfoPhoneNumbers = this.memberInfo.phoneNumbers

    this.model.emailRegister =
      this.memberInfo.email != ''
        ? this.memberInfo.email
        : this.registrationInfo.email ?? ''

    this.model.phone1.textValue = memberInfoPhoneNumbers.primary
      ? memberInfoPhoneNumbers[memberInfoPhoneNumbers.primary.toLowerCase()]
      : this.registrationAutofillPhone.number ?? ''

    this.model.phone1.selectValue =
      memberInfoPhoneNumbers.primary != ''
        ? memberInfoPhoneNumbers.primary
        : this.registrationAutofillPhone.type ?? 'CELL'

    if (this.model.phone1.selectValue === 'HOME') {
      this.model.phone2.selectValue = 'CELL'
    }
  },
  methods: {
    ...mapActions('registration', ['saveContactInfo']),
    handleNext() {
      this.model.phone1.textValue.replace(/-/g, '')
      this.error = null
      this.$v.$touch()

      if (this.$v.$invalid) return
      const model = this.model

      this.saveContactInfo({
        ...model,
        email: this.model.emailRegister,
        phone1: {
          textValue: this.model.phone1.textValue.replace(/-/g, ''),
          selectValue: this.model.phone1.selectValue,
        },
        phone2: {
          textValue: this.model.phone2.textValue.replace(/-/g, ''),
          selectValue: this.model.phone2.selectValue,
        },
      })
      this.$emit('next')
    },
  },
}
</script>
