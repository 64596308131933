<template>
  <div>
    <RegistrationLayoutTitles :title="$t('registration.password.title')" />
    <div class="md:w-435 md:mx-auto">
      <VerifyMfa
        v-if="showVerifyMfa"
        :codeError="mfaCodeError"
        @submitMfaCode="submitMfaCode"
      />
      <div v-else>
        <Form
          :validator="$v.model"
          :error="error"
          :messages="{
            matching: $t('registration.password.matching_error'),
          }"
        >
          <FormInput
            ref="username"
            v-model="model.username"
            :label="$t('registration.password.username')"
            name="username"
            class="mt-20 mb-10"
            type="text"
          />
          <FormInput
            ref="password"
            v-model="model.password"
            :label="$t('registration.password.new_password')"
            name="password"
            placeholder="••••••••"
            secure
            class="mt-20 mb-10"
            type="password"
          />
          <PasswordRequirements :reqs="passwordRequirements" />
          <FormInput
            ref="confirmPassword"
            v-model="model.confirm"
            :label="$t('registration.password.confirm_password')"
            name="confirm"
            placeholder="••••••••"
            secure
            class="mt-12 mb-10"
            type="password"
          />
          <div class="flex">
            <input
              id="terms-checkbox"
              v-model="hasReadTerms"
              type="checkbox"
              class="self-center ml-16 mr-10"
            />
            <label for="terms-checkbox">
              {{ $t('registration.password.agreement') }}
              <router-link
                :to="{
                  name: 'public-privacy-document',
                  params: { title: $t('common.terms_link') },
                }"
                class="underline"
                :title="$t('common.terms')"
                target="_blank"
                >{{ $t('common.terms') }}</router-link
              >
            </label>
          </div>
          <div class="flex">
            <input
              id="privacy-checkbox"
              v-model="hasReadPrivacy"
              type="checkbox"
              class="self-center ml-16 mr-10"
            />
            <label for="privacy-checkbox">
              {{ $t('registration.password.agreement') }}
              <router-link
                :to="{
                  name: 'public-privacy-document',
                  params: { title: $t('common.privacy_link') },
                }"
                class="underline"
                :title="$t('common.privacy')"
                target="_blank"
                >{{ $t('common.privacy') }}</router-link
              >
            </label>
          </div>
        </Form>
        <HMButton
          id="finishButton"
          class="w-full tracking-wide"
          :class="{ 'mt-10': formHasError, 'mt-25': !formHasError }"
          :busy="isFetching"
          :disabled="!hasReadTerms || !hasReadPrivacy || isFetching"
          rounded="full"
          fontWeight="semibold"
          @click="handleFinish"
        >
          {{
            usesAutoRewardsInRegistration
              ? $t('common.next_btn')
              : $t('common.finish_btn')
          }}
        </HMButton>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, FormInput } from '@components/common/forms'
import RegistrationLayoutTitles from '@components/layouts/RegistrationLayoutTitles'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import password from '@platform-shared/mixins/password'
import PasswordRequirements from '@components/common/PasswordRequirements'
import getTime from 'date-fns/getTime'
import VerifyMfa from '../login/VerifyMfa.vue'

export default {
  components: {
    Form,
    FormInput,
    RegistrationLayoutTitles,
    PasswordRequirements,
    VerifyMfa,
  },
  mixins: [password],
  data: () => ({
    hasReadTerms: false,
    hasReadPrivacy: false,
    error: null,
    mfaCodeError: false,
    usernameIsTaken: false,
  }),

  validations: {
    model: {
      username: {
        required,
        validUserNameRegister() {
          return !this.model.username.includes(' ')
        },
        usernameNotTaken() {
          return !this.usernameIsTaken
        },
      },
      password: { required },
      confirm: {
        required,
        matching() {
          return this.model.password === this.model.confirm
        },
      },
    },
  },

  computed: {
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('registration', ['isFetching']),
    ...mapGetters('questionnaire', ['startUpQuestionnaire']),
    ...mapGetters('rewards', [
      'selectedAutoReward',
      'usesAutoRewardsInRegistration',
    ]),
    ...mapGetters('member', [
      'showPhaWelcome',
      'mfaType',
      'preAuthPreferredLanguageCd',
    ]),
    ...mapGetters('app', ['postLoginPath']),
    showVerifyMfa() {
      return !!this.mfaType
    },
    formHasError() {
      return !!this.error
    },
  },

  methods: {
    ...mapActions('registration', [
      'submitRegistration',
      'resetState',
      'postRegistrationMemberInfo',
      'usernameCheck',
    ]),
    ...mapActions('member', ['verifyMfaCode']),
    ...mapActions('app', ['removePostLoginPath']),

    onSuccess() {
      if (!this.mfaType) {
        this.handleRegistered()
      }
    },

    submitMfaCode(totp) {
      this.mfaCodeError = false
      this.verifyMfaCode(totp)
        .then(this.handleRegistered)
        .catch(() => {
          this.mfaCodeError = true
        })
    },

    handleRegistered() {
      this.postRegistrationMemberInfo()
        .then(() => {
          if (this.usesAutoRewardsInRegistration) {
            this.$emit('next')
          } else {
            this.resetState()

            if (this.postLoginPath) {
              this.$router
                .replace(this.postLoginPath)
                .then(this.removePostLoginPath)
            } else if (this.startUpQuestionnaire && !this.showPhaWelcome) {
              localStorage.setItem('fteViewTime', getTime(new Date()))
              this.$router.push(
                `/questionnaire/${this.startUpQuestionnaire.type}`
              )
            } else {
              this.$router.replace('/')
            }
          }
        })
        .catch((error) => {
          this.error = error.message
        })
    },
    async handleFinish() {
      this.error = null
      this.$v.$touch()

      if (this.$v.$invalid) return

      if (!this.meetsPasswordRequirements) {
        this.error = this.$t('registration.password.password_error')
        return
      }

      this.usernameIsTaken = false
      this.usernameCheck(this.model.username)
        .then(() => {
          this.submitRegistration({
            username: this.model.username,
            password: this.model.password,
            preferredLanguageCd: this.preAuthPreferredLanguageCd,
          })
            .then(this.onSuccess)
            .catch((error) => {
              this.error = error.json.message
            })
        })
        .catch(() => {
          this.usernameIsTaken = true
          this.$refs.username.$el
            .querySelectorAll(
              'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            )[0]
            .focus()
        })
    },
  },
}
</script>
