<template>
  <div>
    <RegistrationLayoutTitles
      :title="$t('registration.member_info.title')"
      :subTitle="$t('registration.member_info.description')"
    />
    <div class="md:w-435 md:mx-auto">
      <Form :validator="$v.model" :error="error">
        <FormInput
          v-model="model.firstName"
          :label="$t('registration.member_info.first_name')"
          name="firstName"
          :placeholder="$t('registration.member_info.first_name_place_holder')"
          autocomplete="given-name"
        />
        <FormInput
          v-model="model.lastName"
          class="mt-16"
          :label="$t('registration.member_info.last_name')"
          name="lastName"
          :placeholder="$t('registration.member_info.last_name_place_holder')"
          autocomplete="family-name"
        />
        <div class="relative flex items-center">
          <FormInput
            v-model="model.memberNumber"
            class="w-full mt-16"
            :label="$t('registration.member_info.member_number')"
            name="memberNumber"
            :placeholder="
              $t('registration.member_info.member_number_place_holder')
            "
          />
          <div class="absolute top-0 right-0 pt-5 mr-10 mt-50">
            <v-popover>
              <button
                class="text-center text-white rounded-full bg-grey-500 w-25 h-25 focus:shadow-outline"
                aria-controls="member-number-tooltip"
                :aria-label="
                  $t('registration.member_info.member_number_helper_btn')
                "
              >
                ?
              </button>
              <div id="member-number-tooltip" slot="popover" role="tooltip">
                <div
                  v-for="(card, idx) in insuranceCards"
                  :key="idx"
                  :class="{ 'mb-20': idx !== insuranceCards.length - 1 }"
                >
                  <p v-if="card.name" class="mb-4 font-bold">
                    {{
                      $t('registration.member_info.member_card_members', [
                        card.name,
                      ])
                    }}
                  </p>
                  <img
                    class="w-250"
                    :alt="$t('registration.member_info.member_card_alt_text')"
                    :src="card.file"
                  />
                </div>
              </div>
            </v-popover>
          </div>
        </div>
        <p v-if="memberIdPrompt" class="pt-4 pl-2 text-grey-500">
          {{ memberIdPrompt }}
        </p>

        <FormDatePicker
          v-model="model.birthday"
          class="mt-16"
          :label="$t('registration.member_info.birthday')"
          name="birthday"
          :maxDate="MAX_DATE"
          autocomplete="bday"
        />
        <div v-if="hasGuardianshipFeature" class="mt-20">
          <h3 class="h5">
            {{ $t('registration.member_info.guardianship.label') }}
          </h3>
          <div class="flex justify-start mt-10">
            <input
              id="isGuardian"
              v-model="model.isGuardian"
              type="checkbox"
              class="mt-5 mr-10"
            />
            <label for="isGuardian">
              {{ $t('registration.member_info.guardianship.check') }}
            </label>
          </div>
          <div v-if="model.isGuardian" class="pt-20 mt-20 border-t">
            <p className="border-t mt-16 pt-16">
              {{ $t('registration.member_info.guardianship.message') }}
            </p>
            <h4 class="mt-16 h5">
              {{ $t('registration.member_info.guardianship.title') }}
            </h4>
            <FormInput
              v-model="model.guardianFirstName"
              :label="$t('registration.member_info.first_name')"
              name="guardianFirstName"
              class="mt-16"
              :placeholder="
                $t('registration.member_info.first_name_place_holder')
              "
            />
            <FormInput
              v-model="model.guardianLastName"
              :label="$t('registration.member_info.last_name')"
              name="guardianLastName"
              class="mt-16"
              :placeholder="
                $t('registration.member_info.last_name_place_holder')
              "
            />
            <FormDatePicker
              v-model="model.guardianBirthday"
              class="mt-16"
              :label="$t('registration.member_info.birthday')"
              name="guardianBirthday"
              :maxDate="MAX_MINOR_BIRTH_DATE"
            />
          </div>
        </div>
      </Form>
      <div class="px-40">
        <HMButton
          id="nextButton"
          rounded="full"
          class="w-full tracking-wide"
          fontWeight="semibold"
          :class="{ 'mt-10': formHasError, 'mt-40': !formHasError }"
          :busy="isFetching"
          @click="handleNext"
          >{{ $t('common.next_btn') }}</HMButton
        >
      </div>
    </div>
    <Modal :active="showLanguageChangeModal" @close="cancelLanguageChange">
      <LanguageChangeModal
        @confirm="confirmLanguageChange"
        @cancel="cancelLanguageChange"
      />
    </Modal>
  </div>
</template>

<script>
import { Form, FormInput, FormDatePicker } from '../../common/forms'
import RegistrationLayoutTitles from '@components/layouts/RegistrationLayoutTitles'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import format from 'date-fns/format'
import _get from 'lodash/get'
import _map from 'lodash/map'
import { FEATURES, MAX_MINOR_BIRTH_DATE } from '@platform-shared/constants'
import Modal from '@components/common/Modal'
import LanguageChangeModal from './LanguageChangeModal'

export default {
  components: {
    Form,
    FormInput,
    FormDatePicker,
    RegistrationLayoutTitles,
    Modal,
    LanguageChangeModal,
  },
  data() {
    return {
      showLanguageChangeModal: false,
      model: {
        firstName: '',
        lastName: '',
        memberNumber: '',
        birthday: null,
        isGuardian: false,
        guardianFirstName: '',
        guardianLastName: '',
        guardianBirthday: null,
      },
      error: null,
      isFetching: false,
    }
  },
  validations: {
    model: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      memberNumber: {
        required,
      },
      birthday: {
        required,
      },
      guardianFirstName: {
        required: requiredIf(function() {
          return this.model.isGuardian
        }),
      },
      guardianLastName: {
        required: requiredIf(function() {
          return this.model.isGuardian
        }),
      },
      guardianBirthday: {
        required: requiredIf(function() {
          return this.model.isGuardian
        }),
      },
    },
  },
  computed: {
    ...mapGetters('client', [
      'lingo',
      'memberCards',
      'hasFeature',
      'languages',
    ]),
    ...mapGetters('registration', ['databasePreferredLanguageCd']),
    ...mapGetters('member', ['preAuthPreferredLanguageCd']),
    formHasError() {
      return !!this.error
    },
    insuranceCards() {
      return _map(this.memberCards, (card) => ({
        ...card,
        file: `${window.HM_CONFIG.S3_ROOT}/${card.file}`,
      }))
    },
    memberIdPrompt() {
      return _get(this.lingo, 'memberIdPrompt', '')
    },

    hasGuardianshipFeature() {
      return this.hasFeature(FEATURES.GUARDIANSHIP)
    },
  },
  created() {
    this.MAX_MINOR_BIRTH_DATE = MAX_MINOR_BIRTH_DATE
    this.MAX_DATE = this.hasGuardianshipFeature
      ? new Date()
      : MAX_MINOR_BIRTH_DATE
  },
  methods: {
    ...mapActions('member', ['setPreAuthPreferredLanguage']),
    ...mapActions('registration', [
      'saveMemberInfo',
      'saveAccountInfo',
      'saveGuardianInfo',
      'checkEligibility',
    ]),

    openLanguageChangeModal() {
      this.showLanguageChangeModal = true
    },
    closeLanguageChangeModal() {
      this.showLanguageChangeModal = false
    },
    confirmLanguageChange() {
      this.setPreAuthPreferredLanguage(this.databasePreferredLanguageCd)
      this.$root.$i18n.locale = this.databasePreferredLanguageCd
      this.cancelLanguageChange()
    },
    cancelLanguageChange() {
      this.closeLanguageChangeModal()
      this.$emit('next')
    },
    handleNext() {
      this.error = null
      this.$v.$touch()

      if (this.$v.$invalid) return

      const eligibilityData = {
        ...this.model,
        birthday: format(this.model.birthday, 'yyyy-MM-dd'),
        guardianBirthday:
          this.hasGuardianshipFeature && this.model.isGuardian
            ? format(this.model.guardianBirthday, 'yyyy-MM-dd')
            : '',
      }

      this.checkEligibility(eligibilityData)
        .then(() => {
          this.saveAccountInfo(this.model)
          this.saveMemberInfo(this.model)

          if (this.hasGuardianshipFeature && this.model.isGuardian)
            this.saveGuardianInfo(this.model)

          if (
            this.preAuthPreferredLanguageCd === 'EN' &&
            this.languages.some(
              (lang) => lang.code === this.databasePreferredLanguageCd
            ) &&
            this.preAuthPreferredLanguageCd !== this.databasePreferredLanguageCd
          ) {
            this.openLanguageChangeModal()
          } else {
            this.$emit('next')
          }
        })
        .catch((error) => (this.error = error))
    },
  },
}
</script>
