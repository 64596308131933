<template>
  <RegistrationLayout>
    <template v-slot:header>
      <HMButton
        v-if="step === 2"
        color="white"
        rounded="full"
        size="x-small"
        fontWeight="semibold"
        textSize="small"
        padding="tight"
        @click="back"
      >
        <span>
          <Icon iconClass="fas fa-chevron-left" />
          {{ $t('common.back_btn') }}
        </span>
      </HMButton>
    </template>
    <template v-slot:default>
      <ContactInfo v-if="step === 1" @next="step = 2" />
      <CommunicationPreferences
        v-if="step === 2"
        :isSSORegister="true"
        @next="step = 3"
      />
      <RewardsInfo v-if="step === 3" />
    </template>
  </RegistrationLayout>
</template>

<script>
import ContactInfo from './ContactInfo'
import RewardsInfo from './RewardsInfo'
import CommunicationPreferences from './CommunicationPreferences'
import RegistrationLayout from '@components/layouts/RegistrationLayout'

export default {
  components: {
    RewardsInfo,
    ContactInfo,
    CommunicationPreferences,
    RegistrationLayout,
  },
  data() {
    return {
      step: 1,
    }
  },
  methods: {
    back() {
      this.step--
    },
  },
}
</script>
